<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img
        src="@/assets/logo.png"
        height="35"
        class="c-sidebar-brand-full"
        alt="Logo Sidebar"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="navMenu" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import { mapState } from 'vuex'
import nav from './_nav'

export default {
  name: 'TheSidebar',
  computed: {
    ...mapState({
      isAdmin: state => state.session.loggedUser.is_admin,
      hasSubscription: state => !!state.session.loggedUser.current_subscription
    }),
    navMenu () {
      const navData = nav()
      if (!this.isAdmin) {
        navData[0]._children = navData[0]._children.filter(e => !e.admin && (!e.subscription || this.hasSubscription))
      } else {
        navData[0]._children = navData[0]._children.filter(e => !e.notAdmin)
      }
      return navData
    },
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>
