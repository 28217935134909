const portalLink = '/user/'
export default () => {
  return [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: `${portalLink}dashboard`,
          icon: 'cil-speedometer',
          badge: {
            color: 'primary',
            text: 'NEW'
          }
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Kennels',
          subscription: true,
          to: `${portalLink}kennels`,
          icon: 'cil-home'
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Dogs',
          admin: true,
          icon: 'cil-paw',
          items: [
            {
              name: 'Dogs',
              to: `${portalLink}dogs`
            },
            {
              name: 'Breeds',
              to: `${portalLink}breeds`
            }
          ]
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Dogs',
          subscription: true,
          notAdmin: true,
          to: `${portalLink}dogs`,
          icon: 'cil-paw'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Handlers',
          subscription: true,
          to: `${portalLink}handlers`,
          icon: 'cil-user'
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Events',
          subscription: true,
          icon: 'cil-calendar',
          items: [
            {
              name: 'Events',
              to: `${portalLink}events`
            },
            {
              name: 'Clubs',
              to: `${portalLink}clubs`
            },
            {
              name: 'Locations',
              to: `${portalLink}event_locations`
            },
            {
              name: 'Secretaries',
              to: `${portalLink}secretaries`
            }
          ]
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Stakes',
          admin: true,
          to: `${portalLink}stakes`,
          icon: 'cil-dollar'
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Users & Permissions',
          admin: true,
          icon: 'cil-people',
          items: [
            {
              name: 'Users',
              to: `${portalLink}users`
            },
            {
              name: 'Roles',
              to: `${portalLink}roles`
            },
            {
              name: 'Permissions',
              to: `${portalLink}permissions`
            },
            {
              name: 'Menus',
              to: `${portalLink}/menus`
            }
          ]
        }
      ]
    }
  ]
}
