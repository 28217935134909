<template>
  <CFooter :fixed="false">
    <div>
      Easy Entry Form V1
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <CLink href="https://www.j3fdigital.com" target="_blank">J3F Digital</CLink>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
